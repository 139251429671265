export function setToken(newToken) {
  sessionStorage.setItem('token', newToken);
}

export function getToken() {
  return sessionStorage.getItem('token');
}

export function isAuthenticated() {
  const token = getToken();
  return !!token; 
}