import React, { useState, useEffect } from "react";
import { getAllSentEmails, resendEmail, getEmailById } from "../../../services/ProductionEmailService";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Typography,
  styled,
  CircularProgress,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Chip from "@mui/material/Chip";
import SendIcon from "@mui/icons-material/Send";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const RowHighlightTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const EnhancedTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
}));

const EnhancedTableHeadCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const ProductionEmailTable = () => {
  const [emails, setEmails] = useState({ content: [], totalElements: 0 });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [sendingEmailId, setSendingEmailId] = useState(null);

  useEffect(() => {
    if (searchFilter) {
      fetchEmailById();
    } else {
      fetchEmails();
    }
  }, [page, rowsPerPage, searchFilter]);

  const fetchEmails = async () => {
    setLoading(true);
    try {
      const response = await getAllSentEmails(page, rowsPerPage);
      setEmails(response.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching emails:", error);
      setLoading(false);
    }
  };

  const fetchEmailById = async () => {
    setLoading(true);
    try {
      const response = await getEmailById(searchFilter);
      if (response.data.emailStatus === 'SENT') {
        setEmails({ content: [response.data], totalElements: 1 });
      } else {
        setEmails({ content: [], totalElements: 0 });
      }
      setLoading(false);
    } catch (error) {
      console.log("Error fetching email by ID:", error);
      setLoading(false);
    }
};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewClick = (email) => {
    setSelectedEmail(email);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      return new Date(timestamp).toLocaleString();
    }
    return "";
  };

  const filteredEmails = emails.content.filter(email =>
    email.id ? email.id.toString().includes(searchFilter) : false
  );


  const handleResendEmail = async (email) => {
    const { value: confirm } = await Swal.fire({
      title: "Resend Email",
      text: "Are you sure you want to resend this email?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Send",
      cancelButtonText: "Cancel",
    });

    if (confirm) {
      setSendingEmailId(email.id);
      try {
        const responseMessage = await resendEmail(email.id);
        console.log("Email resend response: ", responseMessage);
        toast.success("Email sent successfully!");
      } catch (error) {
        console.log("Error resending email: ", error);
        toast.error("Unable to resend email");
      } finally {
        setSendingEmailId(null);
      }
    }
  };


  return (
    <TableContainer component={Paper}>
      <Box display="flex" justifyContent="flex-start" p={2}>
        <TextField
          label="Search Email ID"
          value={searchFilter}
          onChange={handleSearchChange}
          variant="outlined"
          size="small"
        />
      </Box>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress style={{ color: '#a12219' }}  size={60} />
        </div>
      ) : (
        <>
          <Table>
            <TableHead>
              <EnhancedTableHeadRow>
                <EnhancedTableHeadCell>Id</EnhancedTableHeadCell>
                <EnhancedTableHeadCell>Email From</EnhancedTableHeadCell>
                <EnhancedTableHeadCell>Status</EnhancedTableHeadCell>
                <EnhancedTableHeadCell>Timestamp Queued</EnhancedTableHeadCell>
                <EnhancedTableHeadCell>Timestamp Sent</EnhancedTableHeadCell>
                <EnhancedTableHeadCell>Actions</EnhancedTableHeadCell>
              </EnhancedTableHeadRow>
            </TableHead>
            <TableBody>
              {filteredEmails.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Typography variant="body1" align="center">
                      No records found.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredEmails.map((email) => (
                  <RowHighlightTableRow key={email.id}>
                    <TableCell>{email.id}</TableCell>
                    <TableCell>
                      <Typography
                        noWrap
                        component="div"
                        style={{ width: "150px", overflow: "hidden" }}
                      >
                        {email.emailFrom}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="inline-flex" alignItems="center" p={1}>
                        <Chip
                          label={email.emailStatus}
                          color={
                            email.emailStatus === "SENT"
                              ? "success"
                              : email.emailStatus === "SENDING"
                              ? "warning"
                              : email.emailStatus === "QUEUED"
                              ? "primary"
                              : "error"
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell>{formatTimestamp(email.timestampQueued)}</TableCell>
                    <TableCell>{formatTimestamp(email.timestampSent)}</TableCell>
                   
                    <TableCell>
                      <IconButton
                        aria-label="View"
                        onClick={() => handleViewClick(email)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>


                  </RowHighlightTableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle align="center">Email Details</DialogTitle>
        <DialogContent>
          {selectedEmail && (
            <div>
              <Typography><strong>Email ID:</strong> {selectedEmail.id}</Typography>
              <Typography><strong>Email From:</strong> {selectedEmail.emailFrom}</Typography>
              <Typography><strong>Email To:</strong> {selectedEmail.emailTo}</Typography>
              <Typography><strong>Email Cc:</strong> {selectedEmail.emailCc}</Typography>
              <Typography><strong>Email Bcc:</strong> {selectedEmail.emailBcc}</Typography>
              <Typography><strong>Email Subject:</strong> {selectedEmail.emailSubject}</Typography>
              <Typography><strong>Email Body:</strong></Typography>
              <div 
                style={{ border: '1px solid #e0e0e0', padding: '10px', marginTop: '8px', borderRadius: '4px', backgroundColor: 'white' }}
                dangerouslySetInnerHTML={{ __html: selectedEmail.emailBody }}
              />
              <Typography><strong>Status:</strong> {selectedEmail.emailStatus}</Typography>
              <Typography><strong>Timestamp Queued:</strong> {formatTimestamp(selectedEmail.timestampQueued)}</Typography>
              <Typography><strong>Timestamp Sent:</strong> {formatTimestamp(selectedEmail.timestampSent)}</Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={emails.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default ProductionEmailTable;
