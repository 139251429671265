import React, { useState, useContext } from 'react';
import {
  AppBar, Toolbar, IconButton, Box,
  Menu, MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import { setToken } from '../../../config/tokenStorage';

const drawerWidth = 200; 

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#ffffff',  
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center', 
    marginLeft: ({ drawerOpen }) => (drawerOpen ? drawerWidth : theme.spacing(7)), 
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logo: {
    height: '40px',
    width: 'auto',
    marginRight: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const NavBar = ({ handleDrawerToggle, drawerOpen }) => {
  const classes = useStyles({ drawerOpen });
  // const { keycloak } = useContext(AuthContext);
  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleLogout = () => {
  //   keycloak.logout({ redirectUri: window.location.origin });
  // };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    window.location.href = '/';
  };


  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Box className={classes.logoContainer}>
          <img src="/77blacktransparent.png" alt="App Logo" className={classes.logo}/>
        </Box>
        <div className={classes.title}></div> 
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          style={{ color: '#a12219' }}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
