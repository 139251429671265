import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/admin/shared/Layout";
import Login from "./pages/admin/Login";
import { isAuthenticated } from "./config/tokenStorage";
import ErrorBoundary from "./error/ErrorBoundary";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const userIsAuthenticated = isAuthenticated();

  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          {userIsAuthenticated ? (
            <>
              <Route path="/" element={<Layout />} />
              <Route path="/login" element={<Navigate replace to="/" />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate replace to="/login" />} />
            </>
          )}
        </Routes>
        <ToastContainer />
      </Router>
    </ErrorBoundary>
  );
};


export default App;

