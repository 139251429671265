import React from 'react';
import ProductionEmailsPage from '../../../pages/admin/ProductionEmailsPage';
import DevelopmentEmailsPage from '../../../pages/admin/DevelopmentEmailsPage';
import SelectionPrompt from './SelectionPrompt';

const MainComponent = ({ selectedEmailType }) => {
  return (
    <div>
      {selectedEmailType === 'Development Emails' && <DevelopmentEmailsPage />}
      {selectedEmailType === 'Production Emails' && <ProductionEmailsPage />}
      {!selectedEmailType && <SelectionPrompt />}
    </div>
  );
  };

export default MainComponent;
