import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "#a12219",
    color: 'white',
    '&:hover': {
      backgroundColor: "#a12219", 
    },
  },
}));

const Sidebar = ({ setSelectedEmailType, selectedEmailType }) => {
  const classes = useStyles();

  return (
    <List>
      <ListItem
        button
        onClick={() => setSelectedEmailType('Production Emails')}
        className={selectedEmailType === 'Production Emails' ? classes.selected : null}
      >
        PRODUCTION EMAILS
      </ListItem>
      <ListItem
        button
        onClick={() => setSelectedEmailType('Development Emails')}
        className={selectedEmailType === 'Development Emails' ? classes.selected : null}
      >
        DEVELOPMENT EMAILS
      </ListItem>
    </List>
  );
};

export default Sidebar;
