import React, { useState} from 'react'
import { setToken } from '../../config/tokenStorage';
import axios from 'axios';


import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Joi from "joi";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    centeredCardHeader: {
      textAlign: 'center',
      color: 'white',
      backgroundColor: '#a12219'
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '30vh',
    },
    logo: {
      marginBottom: '20px',
    },
    text: {
      fontSize: '24px',
      color: '#a12219',
      marginBottom: '5px'
    },
    
  }));

  
const Login = () => {
    const classes = useStyles();

    const [form, setForm] = useState({
        email: "",
        password: "",
      });
      
        const [visibilityPassword, setVisibilityPassword] = useState(false);
      
        const [errors, setErrors] = useState({});
      
        const schema = Joi.object({
          email: Joi.string().required(),
          password: Joi.string().required(),
        });
      
        const handleClickShowPassword = () => {
          setVisibilityPassword(!visibilityPassword);
        };
      
        const handleMouseDownPassword = (event) => {
          event.preventDefault();
        };

        const handleChange = (event) => {
            setForm({ ...form, [event.currentTarget.name]: event.currentTarget.value });
        
            const { error } = schema
            .extract(event.currentTarget.name)
            .label(event.currentTarget.name)
            .validate(event.currentTarget.value);
            if (error) {
            setErrors({
                ...errors,
                [event.currentTarget.name]: error.details[0].message,
            });
            } else {
            delete errors[event.currentTarget.name];
            setErrors(errors);
            }
        };

        const handleSubmit = async (event) => {
            event.preventDefault();
            const jwtAuthUrl = '/api/auth/authenticate';

            try {
              const response = await axios.post(jwtAuthUrl, form);
    
              const { token } = response.data;

          
              setToken(token);
          
              window.location.href = '/';
            } catch (error) {
              if(error && error.response.status === 401){
                toast.error("Invalid username or password");
              }else{
                toast.error("Unknown error occured");
              }
              // console.error('Login error:', error);
              setErrors({ ...errors, form: 'Login failed. Please check your credentials.' });
            }
          };
        
    return (
      <>
      <Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div className={classes.root}>
          <img src="/77blacktransparent.png" alt="Logo" className={classes.logo} />
          <div className={classes.text}>EMAIL SERVICE ADMIN</div>
          </div>
        </Grid>
      </Grid>
       
    <Grid
          container
          justifyContent="center"
          component="form"
          onSubmit={handleSubmit}
        > 
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card style={{ border: '1px solid #a12219' }} >
              <CardHeader title="Enter Admin Credentials" className={classes.centeredCardHeader}/>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      error={!!errors.email}
                      helperText={errors.email}
                      value={form.email}
                      onChange={handleChange}
                      label="Username"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={form.password}
                      onChange={handleChange}
                      error={!!errors.password}
                      helperText={errors.password}
                      label="Password"
                      name="password"
                      type={visibilityPassword ? "text" : "password"}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {visibilityPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      style={{ borderColor: '#a12219' }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={form.username === null || form.username === "" || form.password === null || form.password === ""}
                style={{
                  backgroundColor: (form.username === null || form.username === "" || form.password === null || form.password === "") ? '#cccccc' : '#a12219',
                  color: 'white'
                }}
              >
                Login
              </Button>
              </CardActions>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                mb={1}
              >
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </>
       
      );
    };

export default Login;