import React, { useState } from 'react';
import { Drawer, List, ListItem, CssBaseline, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import MainComponent from './MainComponent';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Layout = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [selectedEmailType, setSelectedEmailType] = useState(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar handleDrawerToggle={handleDrawerToggle} drawerOpen={drawerOpen} />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Sidebar setSelectedEmailType={setSelectedEmailType} selectedEmailType={selectedEmailType} /> 
      </Drawer>
      <main className={clsx(classes.content, {
        [classes.contentShift]: drawerOpen,
      })}>
        <div className={classes.drawerHeader} />
        <MainComponent selectedEmailType={selectedEmailType} />
      </main>
    </div>
  );
};

export default Layout;
