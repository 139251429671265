import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', 
    alignItems: 'center',
    height: '100vh',
    fontSize: '24px',
    color: '#a12219',
    paddingTop: '20vh'
  },
}));

const SelectionPrompt = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      Please select an email type from the sidebar.
    </div>
  );
};

export default SelectionPrompt;
