import React from 'react';
import EmailForm from '../../components/admin/development/EmailForm';
import EmailTable from '../../components/admin/development/EmailTable';
import FailedEmailTable from '../../components/admin/development/FailedEmailTable';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';  


const StyledTab = styled(Tab)({
  color: '#a12219',
  '&.Mui-selected': {
    color: '#a12219'
  },
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid #a12219`,
  '& .MuiTabs-indicator': {
    backgroundColor: '#a12219',
  },
}));

const StyledTypography = styled(Typography)({
  color: '#a12219',
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <StyledTypography component={'span'}>{children}</StyledTypography>
        </Box>
      )}
    </div>
  );
}

 CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const DevelopmentEmailsPage = () => {
  const [value, setValue] = React.useState(0);

const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" align="center" gutterBottom sx={{
        fontWeight: "bold",
        color: "#a12219",
      }}>
        DEVELOPMENT
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <StyledTab label="Email Form" {...a11yProps(0)} />
          <StyledTab label="Sent Emails" {...a11yProps(1)} />
          <StyledTab label="Failed Emails" {...a11yProps(2)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <EmailForm />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <EmailTable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <FailedEmailTable />
      </CustomTabPanel>
    </Box>
  );
}

export default DevelopmentEmailsPage;